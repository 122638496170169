import {acceptHMRUpdate, defineStore, skipHydrate} from 'pinia'
import type {ProductOption} from '~~/layers/api/models/Products'
import type {Promocode} from '~~/layers/api/models/Promocode'
import type {User} from '~~/layers/api/models/User'
import DiscountCalculator from '~~/app/domain/cart/DiscountCalculator'
import {PaymentMethod} from '~~/app/enum/payment'
import type {Calculation, Cart, Customer, Item} from '~~/app/types/cart'

function createInitialState(): Cart {
  return {
    items: [] as Item[],
    customer: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      promoConsent: true,
      policyConsent: true,
    },
    bonus: 0,
    payment: PaymentMethod.Card,
  }
}

export const useCartStore = defineStore('cart', () => {
  const {user} = useSanctumAuth<User>()
  const {productService, cartService, orderService} = useApi()
  const toast = useToast()

  const state = useLocalStorage<Cart>('cart.state', createCartState())
  const errors = ref<string[]>([])
  const isValid = ref(true)

  const installmentsMethods = [
    PaymentMethod.Installments3,
    PaymentMethod.Installments4,
    PaymentMethod.Installments6,
    PaymentMethod.Installments10,
    PaymentMethod.Installments12,
  ]

  const calculation = computed<Calculation>(() => {
    const productsCost = state.value.items.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    )

    const discountAmount = DiscountCalculator.calculate(
      productsCost,
      state.value.discount
    )

    const totalCost = productsCost - discountAmount - state.value.bonus

    return {
      subtotal: productsCost,
      discount: discountAmount,
      total: totalCost,
    }
  })

  const isEmpty = computed(() => state.value.items.length === 0)

  const supportsInstallments = computed(
    () => calculation.value.total >= 3300
  )

  function createCartState(): Cart {
    const cart = createInitialState()

    refreshCartUserDetails(cart)

    return cart
  }

  function refreshCartUserDetails(cart: Cart) {
    if (!user.value) {
      return
    }

    if (!cart.customer) {
      cart.customer = {
        policyConsent: true,
        promoConsent: true,
      } as Customer
    }

    cart.customer.firstName = user.value.first_name
    cart.customer.lastName = user.value.last_name
    cart.customer.email = user.value.email
    cart.customer.phone = user.value.phone_number
  }

  async function addProduct(id: number, option?: ProductOption) {
    const {data: resource} = await productService.getProductById(id)

    const item: Item = {
      id: resource.id,
      name: resource.name,
      price: resource.price,
      oldPrice: resource.old_price,
      quantity: 1,
      image: resource.images.length ? resource.images[0]!.path : undefined,
      option,
    }

    state.value.items.push(item)
  }

  function removeProduct(id: number, option?: ProductOption) {
    const filteredItems = state.value.items.filter(
      (item) =>
        item.id !== id || (item.id === id && item.option !== option)
    )

    state.value.items = filteredItems

    if (filteredItems.length === 0) {
      state.value.discount = undefined
    }
  }

  function setPaymentMethod(payment: PaymentMethod) {
    state.value.payment = payment
  }

  function setCustomer(details: Customer) {
    state.value.customer = details
  }

  function applyPromocode(promocode: Promocode) {
    state.value.discount = {
      promocode: promocode.code,
      type: promocode.type,
      amount: promocode.amount,
    }
  }

  function reset() {
    state.value = createCartState()
  }

  function validateContacts() {
    if (
      state.value.customer.firstName === undefined ||
      state.value.customer.firstName.length === 0
    ) {
      errors.value = [...errors.value, 'Необходимо указать имя']
    }

    if (
      state.value.customer.lastName === undefined ||
      state.value.customer.lastName.length === 0
    ) {
      errors.value = [...errors.value, 'Необходимо указать фамилию']
    }

    if (
      state.value.customer.phone === undefined ||
      state.value.customer.phone.length === 0
    ) {
      errors.value = [...errors.value, 'Необходимо указать телефон']
    }

    if (
      state.value.customer.email === undefined ||
      state.value.customer.email.length === 0
    ) {
      errors.value = [...errors.value, 'Необходимо указать email']
    }
  }

  function validatePolicy() {
    if (state.value.customer.policyConsent) {
      return
    }

    errors.value = [
      ...errors.value,
      'Необходимо дать согласие на обработку персональных данных',
    ]
  }

  async function createOrder() {
    errors.value = []

    validateContacts()
    validatePolicy()

    if (errors.value.length > 0) {
      return
    }

    try {
      const response = await orderService.create(state.value)

      if (response.redirect) {
        reset()
        window.location.href = response.redirect
      }
    } catch (e) {
      const error = useApiError(e)

      if (error.isValidationError) {
        errors.value = Object.entries(error.bag).map(
          ([_, value]) => value[0]!
        )

        return
      }

      toast.error('Не удалось оформить заказ, попробуйте позже')
    }
  }

  async function sync() {
    const productIds = state.value.items.map((item) => item.id)
    const promocode = state.value.discount?.promocode

    const response = await cartService.syncState(productIds, promocode)

    for (const product of state.value.items) {
      const item = response.products.find(
        (item) => item.id === product.id
      )

      if (!item) {
        removeProduct(product.id)
        continue
      }

      product.name = item.name
      product.price = item.price
      product.oldPrice = item.old_price
    }

    if (response.promocode) {
      applyPromocode(response.promocode)
    } else {
      state.value.discount = undefined
    }

    adjustPaymentMethod()
  }

  function adjustPaymentMethod() {
    if (
      installmentsMethods.includes(state.value.payment) &&
      !supportsInstallments.value
    ) {
      setPaymentMethod(PaymentMethod.Card)
    }
  }

  watch(errors, (value) => {
    if (value.length === 0) {
      return
    }

    navigateTo('#errors')
  })

  watch(calculation, () => {
    adjustPaymentMethod()
  })

  return {
    state: skipHydrate(state),
    errors,
    calculation,
    isEmpty,
    isValid,
    supportsInstallments,
    addProduct,
    removeProduct,
    setPaymentMethod,
    setCustomer,
    applyPromocode,
    reset,
    sync,
    refreshCartUserDetails,
    createOrder,
  }
})

// enable HMR for development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
