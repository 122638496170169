import { acceptHMRUpdate, defineStore } from 'pinia'
import type { RemoteConfig } from '~~/layers/api/models/RemoteConfig'

export const useConfigStore = defineStore('config', () => {
  const { remoteConfigService } = useApi()

  const remoteConfig = ref<RemoteConfig>({} as RemoteConfig)

  async function fetch() {
    const { data, error } = await useAsyncData(() =>
      remoteConfigService.get()
    )

    if (error.value) {
      throw error.value
    }

    if (!data.value) {
      return
    }

    remoteConfig.value = data.value
  }

  return {
    remoteConfig,
    fetch,
  }
})

// enable HMR for development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
