import { PromocodeType } from '~~/layers/api/enum/PromocodeType'
import type { Discount } from '~~/app/types/cart'

class DiscountCalculator {
  public calculate(subtotal: number, discount?: Discount): number {
    if (!discount) {
      return 0
    }

    if (discount.type === PromocodeType.Fixed) {
      return discount.amount
    }

    const amount = subtotal * (discount.amount / 100)

    return Math.round(amount)
  }
}

export default new DiscountCalculator()
