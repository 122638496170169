import { acceptHMRUpdate, defineStore } from 'pinia'
import { MenuLocation } from '~~/layers/api/enum/MenuLocation'
import type { ProductCategory } from '~~/layers/api/models/Products'
import MenuTransformer from '~~/app/domain/menu/MenuTransformer'
import type { MenuLink } from '~~/app/types/navigation'

export const useContextStore = defineStore('context', () => {
  const { menuService, productService } = useApi()

  const headerMenu = ref<MenuLink[]>([])
  const footerMenu = ref<MenuLink[]>([])

  const productCategories = ref<ProductCategory[]>([])

  async function fetch() {
    const tasks = [fetchMenu(), fetchProductCategories()]

    await Promise.all(tasks)
  }

  async function fetchMenu() {
    const { data, error } = await useAsyncData(() => menuService.get())

    if (error.value) {
      throw error.value
    }

    if (!data.value) {
      return
    }

    headerMenu.value = MenuTransformer.transform(
      data.value.data,
      MenuLocation.Header
    )

    footerMenu.value = MenuTransformer.transform(
      data.value.data,
      MenuLocation.Footer
    )
  }

  async function fetchProductCategories() {
    const { data, error } = await useAsyncData(() =>
      productService.getCategories()
    )

    if (error.value) {
      throw error.value
    }

    if (!data.value) {
      return
    }

    productCategories.value = data.value.data
  }

  return {
    headerMenu,
    footerMenu,
    productCategories,
    fetch,
  }
})

// enable HMR for development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContextStore, import.meta.hot))
}
