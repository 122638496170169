import type { MenuEntry } from '~~/layers/api/models/Menu'
import type { MenuLink } from '~~/app/types/navigation'

class MenuTransformer {
  public transform(entries: MenuEntry[], location: string): MenuLink[] {
    const filteredEntries = entries.filter(
      (entry) => entry.location === location
    )

    return this.buildTree(filteredEntries)
  }

  private buildTree(entries: MenuEntry[]): MenuLink[] {
    const tree: MenuLink[] = []

    for (const entry of entries) {
      if (entry.parent_id) {
        continue
      }

      tree.push(this.buildNode(entry, entries))
    }

    return tree
  }

  private buildNode(entry: MenuEntry, entries: MenuEntry[]): MenuLink {
    const node: MenuLink = {
      text: entry.name,
      url: entry.url,
      children: [],
    }

    for (const childEntry of entries) {
      if (childEntry.parent_id !== entry.id) {
        continue
      }

      node.children.push(this.buildNode(childEntry, entries))
    }

    return node
  }
}

export default new MenuTransformer()
